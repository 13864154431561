import { createStore } from "vuex";

const store = createStore({
  getters: {
    cancelTokens(state) {
      return state.cancelTokens;
    },
  },

  state() {
    return {
      user: JSON.parse(localStorage.getItem("user")),
      setting: JSON.parse(localStorage.getItem("setting")) ?? {
        address: null,
        phone: null,
        image_url: null,
        email: null,
        youtube_url: null,
        instagram_url: null,
        twitter_url: null,
        linkedin_url: null,
        facebook_url: null,
      },
      cartCount: JSON.parse(localStorage.getItem("cartCount")),
      cancelTokens: [],
    };
  },
  mutations: {
    updateUser(state, newUser) {
      localStorage.setItem("user", JSON.stringify(newUser));
      state.user = newUser;
    },
    updateSetting(state, newSetting) {
      localStorage.setItem("setting", JSON.stringify(newSetting));
      state.setting = newSetting;
    },
    updateCartCount(state, cartCount) {
      localStorage.setItem("setting", JSON.stringify(cartCount));
      state.cartCount = cartCount;
    },
    ADD_CANCEL_TOKEN(state, token) {
      state.cancelTokens.push(token);
    },
    CLEAR_CANCEL_TOKENS(state) {
      state.cancelTokens = [];
    },
  },
  modules: {},
  actions: {
    CANCEL_PENDING_REQUESTS(context) {
      // Cancel all request where a token exists
      context.state.cancelTokens.forEach((request) => {
        if (request.cancel) {
          request.cancel();
        }
      });

      // Reset the cancelTokens store
      context.commit("CLEAR_CANCEL_TOKENS");
    },
  },
});

export default store;
