<template>
  <!-- <div id="ec-overlay"><span class="loader_img"></span></div> -->

  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>
