<template>
  <!-- Footer Start -->
  <footer class="ec-footer section-space-mt">
    <div class="footer-container">
      <div class="footer-top section-space-footer-p">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-lg-3 ec-footer-contact">
              <div class="ec-footer-widget">
                <div class="ec-footer-logo">
                  <router-link to="/">
                    <img :src="$store.state.setting.logo_url" alt="" />
                    <img
                      class="dark-footer-logo"
                      :src="$store.state.setting.logo_url"
                      alt="Site Logo"
                      style="height: 15vh; width: auto; display: none"
                    />
                  </router-link>
                </div>
                <h4 class="ec-footer-heading">Hubungi Kami</h4>
                <div class="ec-footer-links">
                  <ul class="align-items-center">
                    <li class="ec-footer-link">
                      {{ $store.state.setting.address }}
                    </li>
                    <li class="ec-footer-link">
                      <a
                        :href="'tel:' + $store.state.setting.phone"
                        target="_blank"
                        >{{ $store.state.setting.phone }}</a
                      >
                    </li>
                    <li class="ec-footer-link">
                      <a
                        :href="'mailto:' + $store.state.setting.email"
                        target="_blank"
                        >{{ $store.state.setting.email }}</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="col-sm-12 col-lg-2 ec-footer-info"
              v-for="footer in footers"
              :key="footer.id"
            >
              <div class="ec-footer-widget">
                <h4 class="ec-footer-heading">{{ footer.name }}</h4>
                <div class="ec-footer-links">
                  <ul class="align-items-center">
                    <li
                      class="ec-footer-link"
                      v-for="detail in footer.details"
                      :key="detail.id"
                    >
                      <router-link :to="'/footer/' + detail.id">{{
                        detail.title
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom">
        <div class="container">
          <div class="row align-items-center">
            <!-- Footer social Start -->
            <div class="col text-left footer-bottom-left">
              <div class="footer-bottom-social">
                <span class="social-text text-upper">Follow us on:</span>
                <ul class="mb-0">
                  <li class="list-inline-item">
                    <a
                      class="hdr-facebook"
                      target="_blank"
                      :href="$store.state.setting.facebook_url"
                      ><i class="ri-facebook-fill"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      class="hdr-twitter"
                      target="_blank"
                      :href="$store.state.setting.twitter_url"
                      ><i class="ri-twitter-fill"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      class="hdr-instagram"
                      target="_blank"
                      :href="$store.state.setting.instagram_url"
                      ><i class="ri-instagram-line"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      class="hdr-linkedin"
                      target="_blank"
                      :href="$store.state.setting.linkedin_url"
                      ><i class="ri-linkedin-fill"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a
                      class="hdr-instagram"
                      target="_blank"
                      :href="$store.state.setting.youtube_url"
                      ><i class="ri-youtube-fill"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Footer social End -->

            <!-- Footer Copyright Start -->
            <div class="col text-center footer-copy">
              <div class="footer-bottom-copy">
                <div class="ec-copy">
                  Copyright © 2023
                  {{ $store.state.setting.app_name }}. All Rights Reserved
                </div>
              </div>
            </div>
            <!-- Footer Copyright End -->

            <!-- Footer payment -->
            <div class="col footer-bottom-right">
              <!-- <div class="footer-bottom-payment d-flex justify-content-end">
                <div class="payment-link">
                  <img src="/assets/images/icons/payment.png" alt="" />
                </div>
              </div> -->
            </div>
            <!-- Footer payment -->
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer Area End -->
</template>
<script>
export default {
  name: "FooterComponent",
  data: () => ({
    footers: [],
  }),
  mounted() {
    this.$axios.get("/footer").then((res) => {
      if (res) this.footers = res.data.footers;
    });
  },
  methods: {},
};
</script>
