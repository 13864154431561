<template>
  <LayoutMain v-if="$route.meta.layout == 'main'" />
  <LayoutAuth v-if="$route.meta.layout == 'auth'" />
</template>

<script>
import LayoutMain from "./Layouts/LayoutMain.vue";
import LayoutAuth from "./Layouts/LayoutAuth.vue";

export default {
  name: "App",
  components: {
    LayoutMain,
    LayoutAuth,
  },
  mounted() {
    setTimeout(() => {
      this.$axios.get(`/setting`).then((res) => {
        if (res) {
          document
            .getElementsByTagName("meta")
            .namedItem("keywords")
            .setAttribute("content", res.data.setting.app_name);

          document.title = res.data.setting.app_name;

          var link = document.querySelector("link[rel~='icon']");
          if (!link) {
            link = document.createElement("link");
            link.rel = "icon";
            document.head.appendChild(link);
          }
          link.href = res.data.setting.small_logo_url ?? "";

          //state
          this.$store.commit("updateSetting", res.data.setting);
        }
      });

      if (this.$store.state.user) {
        this.$axios.get(`/user`).then((res) => {
          if (res) {
            this.$store.commit("updateUser", res.data.user);
          }
        });
      }
    }, 1000);

    var backdrop = document.getElementsByClassName("modal-backdrop")[0];
    if (backdrop) {
      backdrop.parentNode.removeChild(backdrop);
    }
  },
};
</script>

<style></style>
