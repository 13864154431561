<template>
  <div>
    <header class="ec-header">
      <div class="ec-header-bottom d-none d-lg-block">
        <div class="container position-relative">
          <div class="row">
            <div class="ec-flex">
              <div class="align-self-center">
                <div class="header-logo">
                  <router-link to="/">
                    <img
                      :src="$store.state.setting.logo_url"
                      alt="Site Logo"
                      style="height: 5vh; width: auto"
                    />
                  </router-link>
                </div>
              </div>

              <div class="align-self-center">
                <div class="header-search">
                  <form
                    class="ec-btn-group-form"
                    @submit.prevent="search()"
                    method="get"
                  >
                    <input
                      class="form-control ec-search-bar"
                      placeholder="Cari Produk . . ."
                      type="text"
                      v-model="form.keyword"
                    />
                    <button class="submit" type="button" @click="search()">
                      <img
                        src="/assets/images/icons/search.svg"
                        class="svg_img header_svg"
                        alt=""
                      />
                    </button>
                  </form>
                </div>
              </div>

              <div class="align-self-center">
                <div class="ec-header-bottons">
                  <!-- Header User Start -->
                  <div class="ec-header-user dropdown">
                    <button class="dropdown-toggle" data-bs-toggle="dropdown">
                      <img
                        src="/assets/images/icons/user.svg"
                        class="svg_img header_svg"
                        alt=""
                      />
                    </button>
                    <ul class="dropdown-menu dropdown-menu-right">
                      <li>
                        <router-link
                          to="/panel/profile"
                          v-if="$store.state.user"
                          class="dropdown-item"
                          >Profil</router-link
                        >
                      </li>
                      <li v-if="!$store.state.user">
                        <router-link to="/login" class="dropdown-item"
                          >Login
                        </router-link>
                      </li>
                      <li v-else>
                        <a
                          href="javascript:void(0)"
                          @click="logout()"
                          class="dropdown-item"
                          >Logout</a
                        >
                      </li>
                    </ul>
                  </div>
                  <router-link
                    to="/cart"
                    class="ec-header-btn ec-header-wishlist"
                  >
                    <div class="header-icon">
                      <img
                        src="/assets/images/icons/cart.svg"
                        class="svg_img header_svg"
                        alt=""
                      />
                    </div>
                    <span class="ec-header-count">{{
                      $store.state.cartCount ?? 0
                    }}</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="ec-header-bottom d-lg-none">
        <div class="container position-relative">
          <div class="row">
            <div class="col">
              <div class="header-logo">
                <router-link to="/">
                  <img
                    :src="$store.state.setting.logo_url"
                    alt="Site Logo"
                    style="height: 5vh; width: auto"
                  />
                </router-link>
              </div>
            </div>
            <div class="col">
              <div class="header-search">
                <form
                  class="ec-btn-group-form"
                  @submit.prevent="search()"
                  method="get"
                >
                  <input
                    class="form-control ec-search-bar"
                    placeholder="Cari Produk . . ."
                    type="text"
                    v-model="form.keyword"
                  />
                  <button class="submit" type="button" @click="search()">
                    <img
                      src="/assets/images/icons/search.svg"
                      class="svg_img header_svg"
                      alt="icon"
                    />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="ec-main-menu-desk"
        class="d-none d-lg-block sticky-nav"
        style="height: 50px"
      >
        <div class="container position-relative">
          <div class="row">
            <div class="col-md-12 align-self-center">
              <div class="ec-main-menu">
                <ul>
                  <li v-for="category in categories" :key="category.id">
                    <router-link :to="'/product?category_id=' + category.id"
                      >{{ category.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="ec-mobile-menu"
        class="ec-side-cart ec-mobile-menu"
        :class="{
          'ec-open': sidebarMobile,
        }"
      >
        <div class="ec-menu-title">
          <span class="menu_title">My Menu</span>
          <button class="ec-close" @click="sidebarMobile = !sidebarMobile">
            ×
          </button>
        </div>
        <div class="ec-menu-inner">
          <div class="ec-menu-content">
            <ul v-if="!loading.list">
              <li
                v-for="category in categories"
                :key="category.id"
                @click="sidebarMobile = !sidebarMobile"
              >
                <router-link :to="'/product?category_id=' + category.id">{{
                  category.name
                }}</router-link>
              </li>
            </ul>
            <ul v-else>
              <li v-for="i in 5" :key="i">
                <PuSkeleton height="20px" style="width: 200px" />
              </li>
            </ul>
          </div>
          <div class="header-res-lan-curr">
            <!-- Social Start -->
            <div class="header-res-social">
              <div class="header-top-social">
                <ul class="mb-0">
                  <li class="list-inline-item">
                    <a class="hdr-facebook" href="#"
                      ><i class="ecicon eci-facebook"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a class="hdr-twitter" href="#"
                      ><i class="ecicon eci-twitter"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a class="hdr-instagram" href="#"
                      ><i class="ecicon eci-instagram"></i
                    ></a>
                  </li>
                  <li class="list-inline-item">
                    <a class="hdr-linkedin" href="#"
                      ><i class="ecicon eci-linkedin"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="d-flex justify-content-center align-items-center">
              <button
                class="btn btn-secondary btn-sm"
                v-if="$store.state.user"
                @click="
                  sidebarMobile = !sidebarMobile;
                  logout();
                "
              >
                Logout
              </button>
              <router-link
                to="/login"
                class="btn btn-primary btn-sm"
                v-if="!$store.state.user"
                @click="sidebarMobile = !sidebarMobile"
              >
                Login
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div
        class="ec-side-cart-overlay"
        :class="{
          'd-block': sidebarMobile,
          'd-none': !sidebarMobile,
        }"
        @click="sidebarMobile = !sidebarMobile"
      ></div>
    </header>
  </div>
  <div class="ec-nav-toolbar">
    <div class="container">
      <div class="ec-nav-panel">
        <div class="ec-nav-panel-icons">
          <a
            href="javascript:void(0)"
            @click="sidebarMobile = !sidebarMobile"
            class="navbar-toggler-btn ec-header-btn ec-side-toggle"
            ><img
              src="/assets/images/icons/menu.svg"
              class="svg_img header_svg"
              alt="icon"
          /></a>
        </div>
        <div class="ec-nav-panel-icons">
          <router-link
            :to="'/cart'"
            class="toggle-cart ec-header-btn ec-side-toggle"
            ><img
              src="/assets/images/icons/cart.svg"
              class="svg_img header_svg"
              alt="icon"
            /><span class="ec-cart-noti ec-header-count cart-count-lable">
              {{ $store.state.cartCount ?? 0 }}
            </span></router-link
          >
        </div>
        <div class="ec-nav-panel-icons">
          <router-link to="/" class="ec-header-btn"
            ><img
              src="/assets/images/icons/home.svg"
              class="svg_img header_svg"
              alt="icon"
          /></router-link>
        </div>
        <div class="ec-nav-panel-icons">
          <router-link to="/product" class="ec-header-btn"
            ><img
              src="/assets/images/icons/pack-6.svg"
              class="svg_img header_svg"
              alt="icon"
            />
          </router-link>
        </div>
        <div class="ec-nav-panel-icons">
          <router-link
            :to="$store.state.user ? '/panel/profile' : '/login'"
            class="ec-header-btn"
            ><img
              src="/assets/images/icons/user.svg"
              class="svg_img header_svg"
              alt="icon"
          /></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderComponent",
  data: () => ({
    sidebarMobile: false,
    categories: [],
    form: {
      keyword: null,
    },
    loading: {
      list: 1,
    },
  }),
  mounted() {
    this.$axios
      .get("/category", {
        params: {
          perPage: -1,
        },
      })
      .then((res) => {
        if (res) {
          this.categories = res.data.categories.data;
          this.loading.list = 0;
        }
      });
  },
  methods: {
    search() {
      this.$router.push("/product?keyword=" + this.form.keyword);
    },
    logout() {
      this.$swal({
        title: "Anda Akan Keluar Dari Aplikasi",
        text: "Lanjut?",
        icon: "warning",
        showCancelButton: true,
      }).then((value) => {
        if (value.isConfirmed) {
          this.$axios.post("/logout").then((res) => {
            this.$store.commit("updateUser", null);
            this.$toast.info(res.data.message);
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            delete this.$axios.defaults.headers.Authorization;
            this.$router.push("/");
          });
        }
        return;
      });
    },
  },
};
</script>
